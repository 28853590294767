import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import seeMore from 'images/element/home/see-more-button.svg';

const SeeMoreButton = ({ linkTo }) => {
  return (
    <Link to={linkTo}>
      <div className="flex items-center justify-center p-4">
        <img src={seeMore} alt="more" className="h-4 w-auto" />
      </div>
    </Link>
  );
};
SeeMoreButton.propTypes = {
  linkTo: PropTypes.string.isRequired,
};
SeeMoreButton.defaultProps = {};
export default SeeMoreButton;
