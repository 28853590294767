import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import BeatLoader from 'react-spinners/BeatLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import title from 'images/element/request-form/title.svg';
import { getFormApiURL } from 'components/shared/apiURL';

import {
  TextInputField,
  SelectField,
  TextAreaField,
  EmailInputField,
} from 'components/shared/form';

const ComponentText = {
  NAME_LABEL: 'Name',
  NAME_PLACEHOLDER: 'Your Name',
  EMAIL_LABEL: 'Email',
  EMAIL_PLACEHOLDER: 'Your Email',
  SUBJECT_LABEL: 'Subject',
  SUBJECT_PLACEHOLDER: 'Subject',
  STAFF_LABEL: 'Financial Officer',
  STAFF_PLACEHOLDER: 'Choose your financial officer',
  MESSAGE_LABEL: 'Message',
  MESSAGE_PLACEHOLDER: 'Your Message',
  REQUIRED_VALIDATION: 'ต้องระบุข้อมูล',
  SUBMIT_BUTTON: 'Send',
  REQUEST_SUCCESS: 'REQUEST SENT',
  REQUEST_FAILURE: 'REQUEST FAILURE',
};

const staffList = [
  'Apiwat Supa',
  'Banjamas Sombatsri',
  'Chitra Chankrajang',
  'Patinya Ngowhuad',
  'Pattaramon Krungkraijak',
  'Phensuphang Dontuam',
  'Pornnapas Chairatnanthakul',
  'Thanaphiphat Phiphitthanathat',
  'Thapanun Sangtipkanya',
];

const initialValues = {
  name: '',
  email: '',
  subject: '',
  staff: '',
  message: '',
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required(ComponentText.REQUIRED_VALIDATION),
  email: Yup.string()
    .email()
    .required(ComponentText.REQUIRED_VALIDATION),
  subject: Yup.string().required(ComponentText.REQUIRED_VALIDATION),
  staff: Yup.string().required(ComponentText.REQUIRED_VALIDATION),
  message: Yup.string().required(ComponentText.REQUIRED_VALIDATION),
});

const formURL = getFormApiURL();

const reducer = (state, action) => {
  switch (action.type) {
    case 'init': {
      return {
        ...state,
        status: 'idle',
      };
    }
    case 'request': {
      return {
        ...state,
        status: 'pending',
      };
    }
    case 'success': {
      return {
        ...state,
        status: 'resolved',
      };
    }
    case 'error': {
      return {
        ...state,
        status: 'rejected',
        error: action.error,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const RequestForm = () => {
  const [state, transit] = React.useReducer(reducer, {
    status: 'idle',
    error: null,
  });

  const handleSubmit = async values => {
    transit({ type: 'request' });
    try {
      const response = await axios.post(formURL, {
        ...values,
        type: 'requestConsult',
      });
      if (response && response.status === 200) {
        transit({ type: 'success' });
      } else {
        console.error(`Error: Send mail error`);
        transit({ type: 'error' });
      }
    } catch (error) {
      console.error(`Error`, error);
      transit({ type: 'error' });
    }
  };

  const form = (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {formikBag => {
        const isRequestPending = state.status === 'pending';
        const disabledSubmit =
          !formikBag.dirty || !formikBag.isValid || isRequestPending;
        return (
          <form
            autoComplete="off"
            onSubmit={formikBag.handleSubmit}
            className="w-full"
          >
            <div className="flex flex-col">
              <div className="w-full px-2 py-4 grid grid-cols-2 gap-2">
                <TextInputField
                  required
                  id="name"
                  name="name"
                  placeholder={ComponentText.NAME_PLACEHOLDER}
                  disabled={isRequestPending}
                />
                <EmailInputField
                  required
                  id="email"
                  name="email"
                  placeholder={ComponentText.EMAIL_PLACEHOLDER}
                  disabled={isRequestPending}
                />
                <div className="col-span-2">
                  <TextInputField
                    required
                    id="subject"
                    name="subject"
                    placeholder={ComponentText.SUBJECT_PLACEHOLDER}
                    disabled={isRequestPending}
                  />
                </div>
                <div className="col-span-2">
                  <SelectField
                    required
                    id="staff"
                    name="staff"
                    placeholder={ComponentText.STAFF_PLACEHOLDER}
                    options={staffList}
                    disabled={isRequestPending}
                  />
                </div>
                <div className="col-span-2">
                  <TextAreaField
                    required
                    id="message"
                    name="message"
                    placeholder={ComponentText.MESSAGE_PLACEHOLDER}
                    disabled={isRequestPending}
                  />
                </div>
              </div>
              <div className="px-2">
                <button
                  type="submit"
                  disabled={disabledSubmit}
                  className={
                    `${disabledSubmit ? 'cursor-not-allowed' : 'cursor-auto'}` +
                    ' w-40 py-2 bg-teal-700 border-gray-200 border text-gray-200 font-bold' +
                    ' hover:bg-teal-800'
                  }
                >
                  <div className="flex items-center justify-center">
                    {isRequestPending ? (
                      <BeatLoader
                        loading
                        color="#dfdfdf"
                        size={12}
                        margin={0}
                      />
                    ) : (
                      ComponentText.SUBMIT_BUTTON
                    )}
                  </div>
                </button>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );

  return (
    <div className="flex items-center justify-center w-full h-auto px-10 py-16 bg-main-gradient">
      <div className="w-full grid grid-cols-1 md:grid-cols-2 max-w-screen-lg">
        <div className="flex items-center justify-center">
          <img
            src={title}
            alt="Request a free consultant"
            className="w-3/5 h-auto"
          />
        </div>
        <div className="flex flex-col items-center justify-center mt-4 md:mt-0">
          {state.status === 'resolved' ? (
            <div className="flex flex-col items-center">
              <FontAwesomeIcon
                className="text-5xl text-teal-200"
                icon={['fas', 'check-circle']}
              />
              <div className="mt-3 text-xl text-teal-200">
                {ComponentText.REQUEST_SUCCESS}
              </div>
            </div>
          ) : (
            form
          )}
          {state.status === 'rejected' ? (
            <div className="flex w-full px-2 mt-3">
              <FontAwesomeIcon
                className="text-xl text-red-300"
                icon={['fas', 'exclamation-circle']}
              />
              <div className="text-base text-red-300 ml-3">
                {ComponentText.REQUEST_FAILURE}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default RequestForm;
