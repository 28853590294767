import React from 'react';
import { graphql, StaticQuery, navigate } from 'gatsby';
import Img from 'gatsby-image';
import * as dateFns from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import horizontalLine from 'images/element/horizontal-line.svg';
import SeeMoreButton from './SeeMoreButton';

const ComponentText = {
  TITLE: 'BLOGS',
  READMORE: 'read more',
};

export const query = graphql`
  {
    types: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 3
      filter: { frontmatter: { type: { eq: "blog" } } }
    ) {
      group(field: frontmatter___type) {
        fieldValue
        edges {
          node {
            snippet
            html
            frontmatter {
              path
              title
              date
              featuredImage {
                childImageSharp {
                  fluid(maxWidth: 1300) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const BlogList = () => {
  return (
    <div className="py-12 bg-main grid grid-cols-1 lg:grid-cols-3">
      <div className="flex flex-col justify-between w-full lg:col-span-3">
        <div className="flex items-center justify-center w-full lg:hidden">
          <img src={horizontalLine} alt="horizontal line" className="w-1/2" />
        </div>

        <div className="flex items-center justify-center pt-6 lg:justify-start lg:mt-0 lg:pt-0">
          <div className="text-2xl tracking-wide text-right text-white whitespace-no-wrap md:text-5xl lg:ml-20">
            {ComponentText.TITLE}
          </div>
          <div className="hidden w-full ml-8 lg:flex lg:w-1/2">
            <img
              src={horizontalLine}
              alt="horizontal line"
              className="w-full"
            />
          </div>
        </div>

        <div
          className="flex flex-col w-full px-10 py-6 lg:px-20 bg-main"
          style={{ color: '#3E8180' }}
        >
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
            <StaticQuery
              query={query}
              render={data => {
                let blogs = [];
                if (data.types.group && data.types.group.length > 0) {
                  blogs = data.types.group[0].edges;
                }
                return blogs.map(blog => {
                  const date = dateFns.parse(
                    blog.node.frontmatter.date,
                    'yyyy-MM-dd',
                    new Date()
                  );
                  return (
                    <div
                      key={blog.node.frontmatter.path}
                      className="'flex flex-col overflow-hidden text-gray-900"
                    >
                      <div className="border-4 border-teal-600 opacity-75">
                        <Img
                          alt="Featured"
                          fluid={
                            blog.node.frontmatter.featuredImage.childImageSharp
                              .fluid
                          }
                          className="w-full h-auto"
                        />
                      </div>

                      <div className="flex flex-col p-5 overflow-hidden">
                        <h4 className="text-lg font-semibold text-white truncate">
                          {blog.node.frontmatter.title}
                        </h4>
                        <div className="text-sm text-gray-500">
                          {dateFns.format(date, 'PP')}
                        </div>
                        <div className="w-full mt-2 text-base text-gray-200">
                          <div
                            className=""
                            dangerouslySetInnerHTML={{
                              __html: blog.node.snippet,
                            }}
                          />
                        </div>
                      </div>

                      <div className="flex justify-end px-4 mb-4">
                        <button
                          type="button"
                          className="text-white uppercase bg-secondary hover:bg-teal-800"
                          onClick={() => navigate(blog.node.frontmatter.path)}
                        >
                          <div className="flex items-stretch">
                            <div className="flex items-center w-8 h-8 opacity-75 bg-main">
                              <div className="flex items-center justify-center w-full">
                                <FontAwesomeIcon
                                  className="text-xs"
                                  icon={['fas', 'chevron-right']}
                                />
                              </div>
                            </div>
                            <div className="flex items-center justify-center px-2 text-sm">
                              {ComponentText.READMORE}
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                  );
                });
              }}
            />
          </div>
        </div>

        <div className="flex items-center justify-center w-full py-2">
          <SeeMoreButton linkTo="/blogs" />
        </div>

        <div className="flex items-center justify-center w-full lg:justify-end mt-2">
          <img src={horizontalLine} alt="horizontal line" className="w-1/2" />
        </div>
      </div>
    </div>
  );
};
export default BlogList;
