import React from 'react';

import logoBanner from 'images/element/logo-banner.svg';
import Layout from 'components/Layout';
import SEO from 'components/Seo';
import { HeroSlide, Aboutus, StaffList, BlogList } from 'components/home';
import { RequestForm } from 'components/request-form';

const IndexPage = () => {
  const autoplaySlide = process.env.GATSBY_ENABLE_SLIDE ? true : false;
  return (
    <Layout
      renderContent={({ contentSize }) => {
        return (
          <>
            <SEO title="Home" />
            <div
              className="w-full"
              data-sal="fade"
              data-sal-delay="200"
              data-sal-easing="ease"
            >
              <HeroSlide contentSize={contentSize} autoplay={autoplaySlide} />
            </div>
            <div
              className="flex items-center justify-center w-full h-40 bg-main md:h-56 lg:h-64"
              data-sal="fade"
              data-sal-delay="50"
              data-sal-easing="ease"
            >
              <img
                src={logoBanner}
                alt="logo banner"
                className="object-contain w-full h-full"
              />
            </div>
            <div
              className="w-full"
              data-sal="fade"
              data-sal-delay="50"
              data-sal-easing="ease"
            >
              <Aboutus />
            </div>
            <div
              className="w-full"
              data-sal="fade"
              data-sal-delay="50"
              data-sal-easing="ease"
            >
              <StaffList contentSize={contentSize} />
            </div>

            <div
              className="w-full"
              data-sal="fade"
              data-sal-delay="50"
              data-sal-easing="ease"
            >
              <BlogList contentSize={contentSize} />
            </div>

            <div
              className="w-full"
              data-sal="fade"
              data-sal-delay="50"
              data-sal-easing="ease"
            >
              <RequestForm />
            </div>
          </>
        );
      }}
    />
  );
};
export default IndexPage;
