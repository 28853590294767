import React from 'react';
import PropTypes from 'prop-types';
import Carousel from 'nuka-carousel';
import gsap from 'gsap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import character1 from 'images/element/home/hero-slide/character-1.png';
import character2 from 'images/element/home/hero-slide/character-2.png';
import character3 from 'images/element/home/hero-slide/character-3.png';
import text1 from 'images/element/home/hero-slide/text-1.svg';
import text2 from 'images/element/home/hero-slide/text-2.svg';
import text3 from 'images/element/home/hero-slide/text-3.svg';
import text4 from 'images/element/home/hero-slide/text-4.svg';
import text5 from 'images/element/home/hero-slide/text-5.svg';
import background2 from 'images/element/home/hero-slide/bk-2.png';
import background3 from 'images/element/home/hero-slide/bk-3.png';
import background4 from 'images/element/home/hero-slide/bk-4.png';
import background5 from 'images/element/home/hero-slide/bk-5.png';

import Dimension from 'components/dimension';

const heroSlides = [
  {
    id: 'hrs-1',
    character: character1,
    text: text1,
  },
  {
    id: 'hrs-2',
    character: character2,
    background: background2,
    text: text2,
  },
  {
    id: 'hrs-3',
    character: character3,
    background: background3,
    text: text3,
  },
  {
    id: 'hrs-4',
    background: background4,
    text: text4,
  },
  {
    id: 'hrs-5',
    background: background5,
    text: text5,
  },
];

const HeroSlide = ({ contentSize, autoplay }) => {
  const [slideIndex, setSlideIndex] = React.useState(0);
  const [slideHeight, setSlideHeight] = React.useState(0);
  const contentWidth = React.useRef(0);

  const text1Ref = React.useRef();
  const text2Ref = React.useRef();
  const text3Ref = React.useRef();
  const text4Ref = React.useRef();
  const text5Ref = React.useRef();

  // Lazy initialize gsap
  const [timeline] = React.useState(() => gsap.timeline());

  // Animation
  React.useEffect(() => {
    if (slideIndex === 0) {
      timeline.clear();
      timeline.from(text1Ref.current, {
        // scaleX: 0,
        // transformOrigin: 'right center',
        opacity: 0,
        duration: 2.5,
        delay: 0,
      });
    } else if (slideIndex === 1) {
      timeline.clear();
      timeline.from(text2Ref.current, {
        opacity: 0,
        duration: 2.5,
        delay: 0,
      });
    } else if (slideIndex === 2) {
      timeline.clear();
      timeline.from(text3Ref.current, {
        opacity: 0,
        duration: 2.5,
        delay: 0,
      });
    } else if (slideIndex === 3) {
      timeline.clear();
      timeline.from(text4Ref.current, {
        opacity: 0,
        duration: 2.5,
        delay: 0,
      });
    } else if (slideIndex === 4) {
      timeline.clear();
      timeline.from(text5Ref.current, {
        opacity: 0,
        duration: 2.5,
        delay: 0,
      });
    }
  }, [timeline, slideIndex]);

  React.useEffect(() => {
    // Don't set element height that change in response to changed of `contentSize.height`, the window innerHeight,
    // because on the mobile browser screen height change when user scroll, address bar is hidden,
    // cause element to jump or resize.
    const calSlideHeight = () => {
      if (contentSize.width / contentSize.height < 1.7) {
        // On mobile device,
        // Set height with respect to the window screen width.
        // The slide now will not fit the screen height.
        return contentSize.width / 1.8;
      }
      return contentSize.width / 1.9;
    };

    if (contentWidth.current !== contentSize.width) {
      setSlideHeight(calSlideHeight());
      contentWidth.current = contentSize.width;
    }
  }, [contentSize.width, contentSize.height]);

  return (
    <div
      className="w-full overflow-hidden bg-green-900"
      style={{
        marginTop: Dimension.NAVBAR_HEIGHT,
        height: slideHeight,
      }}
    >
      <Carousel
        wrapAround
        autoplay={autoplay}
        slidesToShow={1}
        swiping={false}
        height={`${slideHeight}px`}
        slideIndex={slideIndex}
        afterSlide={index => setSlideIndex(index)}
        defaultControlsConfig={{
          pagingDotsStyle: {
            fill: '#B18739',
            padding: '0 3px',
          },
        }}
        renderCenterLeftControls={control => {
          const disabled = control.currentSlide === 0;
          return (
            <button
              disabled={disabled}
              className="w-10"
              onClick={control.previousSlide}
            >
              <FontAwesomeIcon
                className={
                  (disabled
                    ? 'opacity-75 text-gray-500'
                    : 'opacity-100 text-white') + ' text-lg md:text-2xl'
                }
                icon={['fas', 'chevron-left']}
              />
            </button>
          );
        }}
        renderCenterRightControls={control => {
          const disabled = control.currentSlide === control.slideCount - 1;
          return (
            <button
              disabled={disabled}
              className="w-10"
              onClick={control.nextSlide}
            >
              <FontAwesomeIcon
                className={
                  (disabled
                    ? 'opacity-75 text-gray-500'
                    : 'opacity-100 text-white') + ' text-lg md:text-2xl'
                }
                icon={['fas', 'chevron-right']}
              />
            </button>
          );
        }}
      >
        <div
          key={heroSlides[0].id}
          style={{
            height: slideHeight,
          }}
          className="relative w-full overflow-hidden bg-main-gradient"
        >
          <div className="absolute bottom-0 right-0 flex justify-end w-1/2 h-auto">
            <div className="w-3/5 mr-10 md:mr-16">
              <img
                src={heroSlides[0].character}
                alt="slide"
                className="object-contain"
              />
            </div>
          </div>

          <div className="absolute top-0 left-0 w-full h-full">
            <div className="w-3/5 h-full pl-12 md:pl-20">
              <div
                className={
                  'flex items-center justify-center h-full' +
                  (slideIndex === 0 ? ' visible' : ' invisible')
                }
              >
                <img
                  ref={text1Ref}
                  src={heroSlides[0].text}
                  alt="slide"
                  className="w-full h-auto"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          key={heroSlides[1].id}
          style={{
            height: slideHeight,
            backgroundImage: `url(${heroSlides[1].background})`,
          }}
          className="relative w-full overflow-hidden bg-cover"
        >
          <div className="absolute bottom-0 left-0 w-1/2 h-auto">
            <div className="w-3/5 ml-10 md:ml-16">
              <img
                src={heroSlides[1].character}
                alt="slide"
                className="object-contain"
              />
            </div>
          </div>
          <div className="absolute top-0 left-0 flex justify-end w-full h-full">
            <div className="w-3/5 h-full pr-12 md:pr-20">
              <div
                className={
                  'flex items-center justify-center h-full' +
                  (slideIndex === 1 ? ' visible' : ' invisible')
                }
              >
                <img
                  ref={text2Ref}
                  src={heroSlides[1].text}
                  alt="slide"
                  className="w-full h-auto"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          key={heroSlides[2].id}
          style={{
            height: slideHeight,
            backgroundImage: `url(${heroSlides[2].background})`,
          }}
          className="relative w-full overflow-hidden bg-cover"
        >
          <div className="absolute bottom-0 left-0 w-1/2 h-auto">
            <div className="w-3/5 ml-10 md:ml-16">
              <img
                src={heroSlides[2].character}
                alt="slide"
                className="object-contain"
              />
            </div>
          </div>
          <div className="absolute top-0 left-0 flex justify-end w-full h-full">
            <div className="w-3/5 h-full pr-12 md:pr-20">
              <div
                className={
                  'flex items-center justify-center h-full' +
                  (slideIndex === 2 ? ' visible' : ' invisible')
                }
              >
                <img
                  ref={text3Ref}
                  src={heroSlides[2].text}
                  alt="slide"
                  className="w-full h-auto"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          key={heroSlides[3].id}
          style={{
            height: slideHeight,
            backgroundImage: `url(${heroSlides[3].background})`,
          }}
          className="relative w-full overflow-hidden bg-cover"
        >
          <div className="absolute top-0 left-0 flex justify-end w-full h-full">
            <div className="w-3/5 h-full pr-12 md:pr-20">
              <div
                className={
                  'flex items-center justify-center h-full' +
                  (slideIndex === 3 ? ' visible' : ' invisible')
                }
              >
                <img
                  ref={text4Ref}
                  src={heroSlides[3].text}
                  alt="slide"
                  className="w-full h-auto"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          key={heroSlides[4].id}
          style={{
            height: slideHeight,
            backgroundImage: `url(${heroSlides[4].background})`,
          }}
          className="relative w-full overflow-hidden bg-cover"
        >
          <div className="absolute top-0 left-0 flex justify-end w-full h-full">
            <div className="w-3/5 h-full pr-12 md:pr-20">
              <div
                className={
                  'flex items-center justify-center h-full' +
                  (slideIndex === 4 ? ' visible' : ' invisible')
                }
              >
                <img
                  ref={text5Ref}
                  src={heroSlides[4].text}
                  alt="slide"
                  className="w-full h-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </Carousel>
    </div>
  );
};
HeroSlide.propTypes = {
  autoplay: PropTypes.bool,
  contentSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
};
HeroSlide.defaultProps = {
  autoplay: true,
  contentSize: { width: 0, height: 0 },
};
export default HeroSlide;
